<template>

<app-content :loading="is.loading && !is.initialised">

	<app-content-head back="Convention.Registration.Items.Edit" :backParams="$route.meta.backParams" title="Comps" subtitle="Manage the comps for the product." :readonly="$authorised('con/registration/access', 'read')">

		<app-content-head-actions-item text="Add" icon="create" v-on:click="onCreateClick" />
		<app-content-head-actions-item text="Remove" icon="delete" :danger="true" :disabled="!selected.length" v-on:click="onDeleteClick" />

	</app-content-head>

	<app-content-filters>

		<app-content-filters-search v-model="filter.search" />

	</app-content-filters>

	<app-content-body :loading="is.loading && is.initialised" :is-empty="!collection.length" placeholder="Sorry, no comps found.">

		<app-table>

			<app-table-head :layout="layout">

				<app-table-column text="Name" />
				<app-table-column />

			</app-table-head>

			<app-table-body :sortable="sortedIndex" v-on:sorted="onSorted">

				<app-table-row v-on:click="onEditClick(item.id)" :layout="layout" v-for="item in collection" :key="item.id">

					<app-table-cell-text :text="item.name" />
					<app-table-cell-select :active="isSelected(item.id)" v-on:click="onSelectToggle(item.id)" />

				</app-table-row>

			</app-table-body>

			<app-table-foot :pagination="pagination" :total="total" />

		</app-table>

	</app-content-body>

</app-content>

</template>

<script>

import mixCollection from '@/mixin/collection'

export default {

	mixins: [mixCollection],

	data: function() {

		return {
			pagination: {
				perpage: 100000
			},
			layout: 'auto 24px',
			gatekeep: 'convention/registration/item',
			endpoint: 'convention/registration/item/:item/comps',
			live: 'convention/registration/item/:item/comps'
		}

	}

}

</script>

<style scoped>

</style>